import { useState } from "react";
import Modal from "react-modal";
import Promo from "./promo";
import Glitch from "../../components/glitch";
import Typewriter from "typewriter-effect";
import styled, { keyframes } from "styled-components";
import bgHero from "./png3d.png";


const heroContent = {
  heroMobileImage: "img-mobile",
  heroDescriptions: `Our design and engineering services are transparent,
  flexible and cooperative to realize your vision according
  to your conditions with a professional conclusion.`,
  heroBtn: "PROMO",
};
const Type = styled.div`
            font-size: 25px;
            line-height: 50px;
            color: #fff;
            text-transform: uppercase;
            margin-left: 6px;
            margin-bottom: 25px;
            @media (min-width:768px) and (max-width:1500px) {
                font-size: 23px;
                line-height: 20px;
            }
            @media (max-width:767px) {
                font-size: 20px;
                line-height: 20px;
            }
            span {
                font-family: 'Hemi Head';
            }
            `
        ;
        const AnimatedShadow = keyframes`
        0%   {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
        10%  {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
        20%  {box-shadow: 0 28px 60px rgba(239, 78, 123, .5);}
        30%  {box-shadow: 0 28px 60px rgba(161, 102, 171, .5);}
        40% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
        50% {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
        60% {box-shadow: 0 28px 60px rgba(7, 179, 155, .5);}
        70% {box-shadow: 0 28px 60px rgba(111, 186, 130, .5);}
        80% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
        90% {box-shadow: 0 28px 60px rgba(16, 152, 173, .5);}
        100% {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
    `;
    const ImageContainer = styled.div`
            overflow: hidden;
            animation: ${AnimatedShadow} 10s infinite alternate;
            img {
                transform:scale(1.5);
            }
        `;
const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="row home-details-container align-items-center">
            <div className="col-lg-4 bg position-fixed d-none d-lg-block">
                <img height="100%" src={bgHero} alt="Graphic Design"></img>
            </div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
          <div>
         
            <Glitch text="Hall Of Fame" />
            <p className="email-inquiries">INFO@ABLIMITS.COM</p>

            <p className="display-none-mb poppins-font">{heroContent.heroDescriptions}</p>
            <Type>
              <Typewriter
                options={{
                strings: [
                  'Web Development',
                  'Graphic Design',
                  'Video Creator',
                  'SEO → Marketing',
    
                ],
                autoStart: true,
                loop: true,
                }}
                />
            </Type>
            <button className="button" onClick={toggleModalOne}>
              <span className="button-text">{heroContent.heroBtn}</span>
              <i class="fas fa-chevron-right button-icon"></i>
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal dark hero"
        overlayClassName="custom-overlay dark"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/cancel.png" alt="close icon" />
          </button>
          <div className="box_inner about" style={{paddingBottom:'0'}}>
            <div data-aos="fade-up" data-aos-duration="1200">
              <Promo />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Hero;

import styled from 'styled-components'

export const GradientContainer = styled.div`
  border: solid 5px transparent;
  border-radius: 5px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px;
    border-radius: inherit;
    background: linear-gradient(to bottom right, rgb(237, 81, 121), rgb(70, 121, 182));
  }
`
import React from "react";
import Cover1 from "../covers/1.gif";

const ModalOneBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Post <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> ABlimits Studio
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 11 November 2021
        </span>
        <span>
          <i className="fa fa-tags"></i> Digital, business, economy, design
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>5 exciting web design trends for 2022</h1>
      <img src={Cover1} className="img-fluid" alt="Blog" />
      <div className="blog-excerpt open-sans-font">
        <p>
        We’ll take a look at some of the trends to look out for in 2022 when it comes to web design trends and these cover a wide spectrum of ideas.
        </p>
        
        <strong>One Page Websites</strong>
        
        <p>
        Since we’re always looking for simplicity, and have gotten used to scrolling on our mobile phones of what seems like an endless stream of content, this has transferred over to traditional web design as well. Setting up your entire site to look as if it’s one scrollable page allows for the user to be able to get to the information they need quickly. Enabling you to have a feeling of being overwhelmed or spending time going through the different menu options.
        </p>
        <strong>Home Page video or mini animations</strong>
        <p>More and more sites are opting for an embedded video right on their homepage. It's a great way to showcase the product or service immediately and see it in action. This type of content will be utilised more into 2022 and beyond, as a core feature due to the fact that it's easier now more than ever to keep a site loading faster, regardless of the amount of content on it. 
This also is the same for mini animations, for those that are looking for a trendier way of presenting their sites. These mini animations provide just the right touch to grab attention to where you want users to go. 
</p>
        <strong>Scrolling a new way</strong>
        <p>More sites have been experimenting and progressing with scrolling horizontally versus the traditional vertical method. This enables a multi-carousel look and feel on any page, where either content or products can easily be broken down into their respective categories and presented in an alternative way for the user to experience it. A long time coming and definitely trending more into the new year.
</p>
        <strong>Light OR Dark modes</strong>
        <p>The pandemic had a lot of us staring at our screens at all hours of the day or night. This means we got more used to that wellness feature on our smartphones and computers to switch on blue filters and the coveted dark mode. This has transitioned to websites as well, where more are offering the site to be viewed in dark mode, to reduce that eye strain, especially for those sites that choose brighter colours as their core branding. 
</p>
       <strong>Choose your own website</strong>
        <p>Something that is slowly starting to happen is the development of personalised websites. The idea is to develop multiple versions of the same site on the backend and offer the user a choice of what type of site they would like to see first. 
</p>
<div className="quotebox">
          <div className="icon">
            <img src="img/blog/quote.svg" alt="blog quote" />
          </div>
          <p>
          All of these will trends will be taking over the web design industry
          in the oncoming year and here at ABLIMITS
          we will be at the cutting edge of these techniques.<br/>
          <a href="mailto:info@ablimits.com">Contact us today</a> for more details on how to best optimise the design of your website.
          </p>
        </div>
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalOneBlogContent;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  width: 100%;
  height: auto;
  padding: 20px 60px;
  box-sizing: border-box;
  padding-bottom: 50px;
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom:70px;
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1,1fr);
    padding:20px 40px;
    padding-bottom:70px;
  }

`;

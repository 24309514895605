import styled, { css } from 'styled-components'
import Select from 'react-dropdown-select'

export const StyledForm = styled.form`
  font-family: 'Hemi Head', sans-serif;
`

export const StyledInput = styled.input`
  font-family: 'Hemi Head', sans-serif;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border: 1.5px solid rgb(45, 45, 45);
  color: rgb(160, 160, 160);
  border-radius: 1px;
  height: 36px;
  padding-left: 8px;
`

export const HiddenInput = styled.input`
  display: none;
`

export const FileInputLabel = styled.label`
  padding: 5px 5px;
  ${props => props.isattachment ? css`
    background: rgb(45, 45, 45);
    color: #FFF;
  ` : css`
    background: linear-gradient(45deg, rgba(41, 153, 153, 1) 0%, rgba(50, 189, 189, 1) 25%, rgba(4, 229, 229, 1) 51%, rgba(41, 153, 153, 1) 100%);
    color: #FFF;
  `}
`

export const StyledSelect = styled(Select)`
  border: 1.5px solid rgb(45, 45, 45) !important;
  color: rgb(160, 160, 160);
  span {
    font-size: 14px;
    padding-left: 4px;
  }
`

export const StyledTextarea = styled.textarea`
  font-family: 'Hemi Head', sans-serif;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border: 1.5px solid rgb(45, 45, 45);
  color: rgb(115, 115, 115);
  border-radius: 1px;
  padding: 12px 8px;
  resize: none;
  height:120px;
`

export const CenteredContent = styled.div`
  text-align: center;
`

export const Header = styled.h2`
  color: #FFF;
  margin: 0;
  font-size:1.2em;
`

export const SubHeader = styled.h3`
  color: rgb(115, 115, 115);
  margin: 0;
  font-size:1em;
`

export const Submit = styled.button`
  display: block;
  height: 38px;
  width: 116px;
  position: relative;
  border: none;
  overflow: hidden;
  transition: .5s;
  margin: 0 auto;
  margin-top: -15px;

  &::after {
    position: absolute;
    content: '';
    display: inline-block;
    background: rgba(59, 173, 227, 1);
    background: linear-gradient(to bottom right, rgb(237, 81, 121), rgb(70, 121, 182));
    height: 100%;
    width: 140%;
    top: 0;
    left: 0;
    transition: .5s;
  }

  &:hover {
    &::after {
      transform: translateX(-20%);
    }
  }

  span {
    font-family: 'Hemi Head', sans-serif;
    position: relative;
    top: 0;
    color: #fff;
    z-index: 10;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`

export const StyledLabel = styled.label`
  margin-left: 8px;
  font-family: 'Hemi Head', sans-serif;
  color: rgb(160, 160, 160);
`

export const StyledSelectItem = styled.div`
  padding: 2px;
  color: #555;
  border-radius: 3px;
  margin: 1px;
  cursor: pointer;
  background-color: transparent !important;
  

  > div {
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 10px;
  }

  :hover {
    background: #f2f2f2;
  }
`
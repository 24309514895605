import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "react-modal";

// Web Design
import Aderma from "./modal/webdesign/Aderma";
import Albamarkt from "./modal/webdesign/Albamarkt";
import AustralianBuilding from "./modal/webdesign/AustralianBuilding";
import Breon from "./modal/webdesign/Breon";
import Fakurti from "./modal/webdesign/Fakurti";
import FliesenPaci from "./modal/webdesign/FliesenPaci";
import Ilyonis from "./modal/webdesign/Ilyonis";
import JaInnen from "./modal/webdesign/JaInnen";
import LamaHausmeister from "./modal/webdesign/LamaHausmeister";
import Lavita from "./modal/webdesign/Lavita";
import Morina from "./modal/webdesign/Morina";
import NovaGroup from "./modal/webdesign/NovaGroup";
import AutoPlatform from "./modal/webdesign/AutoPlatform";
import CreativeAgency from "./modal/webdesign/CreativeAgency";
import InnovaSpa from "./modal/webdesign/InnovaSpa";

// Templates

import TemplateOne from "./modal/templates/TemplateOne";
import TemplateTwo from "./modal/templates/TemplateTwo";
import TemplateThree from "./modal/templates/TemplateThree";
import TemplateFour from "./modal/templates/TemplateFour";
import TemplateFive from "./modal/templates/TemplateFive";
import TemplateSix from "./modal/templates/TemplateSix";
import TemplateSeven from "./modal/templates/TemplateSeven";
import TemplateEight from "./modal/templates/TemplateEight";
import TemplateNine from "./modal/templates/TemplateNine";
import TemplateTen from "./modal/templates/TemplateTen";
import TemplateEleven from "./modal/templates/TemplateEleven";
import TemplateTwelve from "./modal/templates/TemplateTwelve";
import TemplateThirteen from "./modal/templates/TemplateThirteen";
import TemplateFourteen from "./modal/templates/TemplateFourteen";
import TemplateFiveteen from "./modal/templates/TemplateFiveteen";


// Brand Identity
import ModalBrandOne from "./modal/brand/ModalBrandOne";
import ModalBrandTwo from "./modal/brand/ModalBrandTwo";
import ModalBrandThree from "./modal/brand/ModalBrandThree";
import ModalBrandFour from "./modal/brand/ModalBrandFour";
import ModalBrandFive from "./modal/brand/ModalBrandFive";
import ModalBrandSix from "./modal/brand/ModalBrandSix";
import ModalBrandSeven from "./modal/brand/ModalBrandSeven";
import ModalBrandEight from "./modal/brand/ModalBrandEight";
import ModalBrandNine from "./modal/brand/ModalBrandNine";
import ModalBrandTen from "./modal/brand/ModalBrandTen";
import ModalBrandEleven from "./modal/brand/ModalBrandEleven";
import ModalBrandTwelve from "./modal/brand/ModalBrandTwelve";
import ModalBrandThirteen from "./modal/brand/ModalBrandThirteen";
import ModalBrandFourteen from "./modal/brand/ModalBrandFourteen";
import ModalBrandFiveteen from "./modal/brand/ModalBrandFiveteen";

// Multimedia
import ModalVideoOne from "./modal/multimedia/ModalVideoOne";
import ModalVideoTwo from "./modal/multimedia/ModalVideoTwo";
import ModalVideoThree from "./modal/multimedia/ModalVideoThree";
import ModalVideoFour from "./modal/multimedia/ModalVideoFour";
import ModalVideoFive from "./modal/multimedia/ModalVideoFive";
import ModalVideoSix from "./modal/multimedia/ModalVideoSix";
import ModalVideoSeven from "./modal/multimedia/ModalVideoSeven";
import ModalVideoEight from "./modal/multimedia/ModalVideoEight";
import ModalVideoNine from "./modal/multimedia/ModalVideoNine";

const Portfolio = () => {

  //web design
  const [isOpen50, setIsOpen50] = useState(false);
  const [isOpen51, setIsOpen51] = useState(false);
  const [isOpen52, setIsOpen52] = useState(false);
  const [isOpen53, setIsOpen53] = useState(false);
  const [isOpen54, setIsOpen54] = useState(false);
  const [isOpen55, setIsOpen55] = useState(false);
  const [isOpen56, setIsOpen56] = useState(false);
  const [isOpen57, setIsOpen57] = useState(false);
  const [isOpen58, setIsOpen58] = useState(false);
  const [isOpen59, setIsOpen59] = useState(false);
  const [isOpen60, setIsOpen60] = useState(false);
  const [isOpen61, setIsOpen61] = useState(false);
  const [isOpen62, setIsOpen62] = useState(false);
  const [isOpen63, setIsOpen63] = useState(false);
  const [isOpen64, setIsOpen64] = useState(false);

  function toggleAderma() {
    setIsOpen50(!isOpen50);
  }
  function toggleAlbamarkt() {
    setIsOpen51(!isOpen51);
  }
  function toggleAustralianBuilding() {
    setIsOpen52(!isOpen52);
  }
  function toggleBreon() {
    setIsOpen53(!isOpen53);
  }
  function toggleFakurti() {
    setIsOpen54(!isOpen54);
  }
  function toggleFliesenPaci() {
    setIsOpen55(!isOpen55);
  }
  function toggleIlyonis() {
    setIsOpen56(!isOpen56);
  }
  function toggleJaInnen() {
    setIsOpen57(!isOpen57);
  }
  function toggleLamaHausmeister() {
    setIsOpen58(!isOpen58);
  }
  function toggleLavita() {
    setIsOpen59(!isOpen59);
  }
  function toggleMorina() {
    setIsOpen60(!isOpen60);
  }
  function toggleNovaGroup() {
    setIsOpen61(!isOpen61);
  }
  function toggleAutoPlatform() {
    setIsOpen62(!isOpen62);
  }
  function toggleCreativeAgency() {
    setIsOpen63(!isOpen63);
  }
  function toggleInnovaSpa() {
    setIsOpen64(!isOpen64);
  }
  //web design end

  //brand identiy
  const [isOpen300, setIsOpen300] = useState(false);
  const [isOpen301, setIsOpen301] = useState(false);
  const [isOpen302, setIsOpen302] = useState(false);
  const [isOpen303, setIsOpen303] = useState(false);
  const [isOpen304, setIsOpen304] = useState(false);
  const [isOpen305, setIsOpen305] = useState(false);
  const [isOpen306, setIsOpen306] = useState(false);
  const [isOpen307, setIsOpen307] = useState(false);
  const [isOpen308, setIsOpen308] = useState(false);
  const [isOpen309, setIsOpen309] = useState(false);
  const [isOpen310, setIsOpen310] = useState(false);
  const [isOpen311, setIsOpen311] = useState(false);
  const [isOpen312, setIsOpen312] = useState(false);
  const [isOpen313, setIsOpen313] = useState(false);
  const [isOpen314, setIsOpen314] = useState(false);

  function toggleModalBrandOne() {
    setIsOpen300(!isOpen300);
  }
  function toggleModalBrandTwo() {
    setIsOpen301(!isOpen301);
  }
  function toggleModalBrandThree() {
    setIsOpen302(!isOpen302);
  }
  function toggleModalBrandFour() {
    setIsOpen303(!isOpen303);
  }
  function toggleModalBrandFive() {
    setIsOpen304(!isOpen304);
  }
  function toggleModalBrandSix() {
    setIsOpen305(!isOpen305);
  }
  function toggleModalBrandSeven() {
    setIsOpen306(!isOpen306);
  }
  function toggleModalBrandEight() {
    setIsOpen307(!isOpen307);
  }
  function toggleModalBrandNine() {
    setIsOpen308(!isOpen308);
  }
  function toggleModalBrandTen() {
    setIsOpen309(!isOpen309);
  }
  function toggleModalBrandEleven() {
    setIsOpen310(!isOpen310);
  }
  function toggleModalBrandTwelve() {
    setIsOpen311(!isOpen311);
  }
  function toggleModalBrandThirteen() {
    setIsOpen312(!isOpen312);
  }
  function toggleModalBrandFourteen() {
    setIsOpen313(!isOpen313);
  }
  function toggleModalBrandFiveteen() {
    setIsOpen314(!isOpen314);
  }
  //brand identity end

  //multimedia
  const [isOpen200, setIsOpen200] = useState(false);
  const [isOpen201, setIsOpen201] = useState(false);
  const [isOpen202, setIsOpen202] = useState(false);
  const [isOpen203, setIsOpen203] = useState(false);
  const [isOpen204, setIsOpen204] = useState(false);
  const [isOpen205, setIsOpen205] = useState(false);
  const [isOpen206, setIsOpen206] = useState(false);
  const [isOpen207, setIsOpen207] = useState(false);
  const [isOpen208, setIsOpen208] = useState(false);

  function toggleVideoOne() {
    setIsOpen200(!isOpen200);
  }
  function toggleVideoTwo() {
    setIsOpen201(!isOpen201);
  }
  function toggleVideoThree() {
    setIsOpen202(!isOpen202);
  }
  function toggleVideoFour() {
    setIsOpen203(!isOpen203);
  }
  function toggleVideoFive() {
    setIsOpen204(!isOpen204);
  }
  function toggleVideoSix() {
    setIsOpen205(!isOpen205);
  }
  function toggleVideoSeven() {
    setIsOpen206(!isOpen206);
  }
  function toggleVideoEight() {
    setIsOpen207(!isOpen207);
  }
  function toggleVideoNine() {
    setIsOpen208(!isOpen208);
  }
  //multimedia end

  //templates
  const [isOpen101, setIsOpen101] = useState(false);
  const [isOpen102, setIsOpen102] = useState(false);
  const [isOpen103, setIsOpen103] = useState(false);
  const [isOpen104, setIsOpen104] = useState(false);
  const [isOpen105, setIsOpen105] = useState(false);
  const [isOpen106, setIsOpen106] = useState(false);
  const [isOpen107, setIsOpen107] = useState(false);
  const [isOpen108, setIsOpen108] = useState(false);
  const [isOpen109, setIsOpen109] = useState(false);
  const [isOpen110, setIsOpen110] = useState(false);
  const [isOpen111, setIsOpen111] = useState(false);
  const [isOpen112, setIsOpen112] = useState(false);
  const [isOpen113, setIsOpen113] = useState(false);
  const [isOpen114, setIsOpen114] = useState(false);
  const [isOpen115, setIsOpen115] = useState(false);

  function toggleTemplateOne() {
    setIsOpen101(!isOpen101);
  }
  function toggleTemplateTwo() {
    setIsOpen102(!isOpen102);
  }
  function toggleTemplateThree() {
    setIsOpen103(!isOpen103);
  }
  function toggleTemplateFour() {
    setIsOpen104(!isOpen104);
  }
  function toggleTemplateFive() {
    setIsOpen105(!isOpen105);
  }
  function toggleTemplateSix() {
    setIsOpen106(!isOpen106);
  }
  function toggleTemplateSeven() {
    setIsOpen107(!isOpen107);
  }
  function toggleTemplateEight() {
    setIsOpen108(!isOpen108);
  }
  function toggleTemplateNine() {
    setIsOpen109(!isOpen109);
  }
  function toggleTemplateTen() {
    setIsOpen110(!isOpen110);
  }
  function toggleTemplateEleven() {
    setIsOpen111(!isOpen111);
  }
  function toggleTemplateTwelve() {
    setIsOpen112(!isOpen112);
  }
  function toggleTemplateThirteen() {
    setIsOpen113(!isOpen113);
  }
  function toggleTemplateFourteen() {
    setIsOpen114(!isOpen114);
  }
  function toggleTemplateFiveteen() {
    setIsOpen115(!isOpen115);
  }
  //templates end

  return (
    <Tabs>
      <TabList className="portfolio-tab-list">
        <Tab>Web Design</Tab>
        <Tab>Brand Identity</Tab>
        <Tab>Multimedia</Tab>
        <Tab>Templates</Tab>
      </TabList>

      <div className="portfolio-tab-content">
        

        <TabPanel>
          <ul className="row grid justify-content-center padding-ab">
            {/* <!-- Breon Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleBreon}
            >
              <img src="img/projects/webDesign/1.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Breon
                </span>
              </div>
            </figure>

            {/* Start BreonContent */}
            <Modal
              isOpen={isOpen53}
              onRequestClose={toggleBreon}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleBreon}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <Breon />
                </div>
              </div>
            </Modal>
            {/* End  BreonContent */}
            </li>
            {/* <!-- Breon Item End --> */}

            {/* <!-- Australian Building Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleAustralianBuilding}
            >
              <img src="img/projects/webDesign/2.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Australian Building Specialist
                </span>
              </div>
            </figure>

            {/* Start AustralianBuildingContent */}
            <Modal
              isOpen={isOpen52}
              onRequestClose={toggleAustralianBuilding}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleAustralianBuilding}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <AustralianBuilding />
                </div>
              </div>
            </Modal>
            {/* End  AustralianBuildingContent */}
            </li>
            {/* <!-- Australian Building Item End --> */}
            {/* <!-- ADERMA Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleAderma}
            >
              <img src="img/projects/webDesign/3.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  ADERMA SHPK
                </span>
              </div>
            </figure>

            {/* Start AdermaContent */}
            <Modal
              isOpen={isOpen50}
              onRequestClose={toggleAderma}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleAderma}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <Aderma />
                </div>
              </div>
            </Modal>
            {/* End  AdermaContent */}
            </li>
            {/* <!-- ADERMA Item End --> */}
            
            {/* <!-- AutoPlatform Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleAutoPlatform}
            >
              <img src="img/projects/webDesign/4.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Auto Platform
                </span>
              </div>
            </figure>

            {/* Start AutoPlatformContent */}
            <Modal
              isOpen={isOpen62}
              onRequestClose={toggleAutoPlatform}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleAutoPlatform}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <AutoPlatform />
                </div>
              </div>
            </Modal>
            {/* End  AutoPlatformContent */}
            </li>
            {/* <!-- AutoPlatform Item End --> */}
            {/* <!-- InnovaSpa Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleInnovaSpa}
            >
              <img src="img/projects/webDesign/5.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Innova Spa
                </span>
              </div>
            </figure>

            {/* Start InnovaSpaContent */}
            <Modal
              isOpen={isOpen64}
              onRequestClose={toggleInnovaSpa}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleInnovaSpa}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <InnovaSpa />
                </div>
              </div>
            </Modal>
            {/* End  InnovaSpaContent */}
            </li>
            {/* <!-- InnovaSpa Item End --> */}
            {/* <!-- CreativeAgency Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleCreativeAgency}
            >
              <img src="img/projects/webDesign/6.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Creative Agency
                </span>
              </div>
            </figure>

            {/* Start CreativeAgencyContent */}
            <Modal
              isOpen={isOpen63}
              onRequestClose={toggleCreativeAgency}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleCreativeAgency}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <CreativeAgency />
                </div>
              </div>
            </Modal>
            {/* End  CreativeAgencyContent */}
            </li>
            {/* <!-- CreativeAgency Item End --> */}
            {/* <!-- Morina Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleMorina}
            >
              <img src="img/projects/webDesign/7.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Morina Dekor
                </span>
              </div>
            </figure>

            {/* Start MorinaContent */}
            <Modal
              isOpen={isOpen60}
              onRequestClose={toggleMorina}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleMorina}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <Morina />
                </div>
              </div>
            </Modal>
            {/* End  MorinaContent */}
            </li>
            {/* <!-- Morina Item End --> */}
            {/* <!-- JaInnenausbau Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleJaInnen}
            >
              <img src="img/projects/webDesign/8.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Ja Innenausbau
                </span>
              </div>
            </figure>

            {/* Start JaInnenausbauContent */}
            <Modal
              isOpen={isOpen57}
              onRequestClose={toggleJaInnen}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleJaInnen}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <JaInnen />
                </div>
              </div>
            </Modal>
            {/* End  JaInnenausbauContent */}
            </li>
            {/* <!-- JaInnenausbau Item End --> */}
            {/* <!-- FliesenPaci Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleFliesenPaci}
            >
              <img src="img/projects/webDesign/9.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Fliesen Paci
                </span>
              </div>
            </figure>

            {/* Start FliesenPaciContent */}
            <Modal
              isOpen={isOpen55}
              onRequestClose={toggleFliesenPaci}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleFliesenPaci}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <FliesenPaci />
                </div>
              </div>
            </Modal>
            {/* End  FliesenPaciContent */}
            </li>
            {/* <!-- FliesenPaci Item End --> */}
            {/* <!-- Fakurti Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleFakurti}
            >
              <img src="img/projects/webDesign/10.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Fa Kurti
                </span>
              </div>
            </figure>

            {/* Start FakurtiContent */}
            <Modal
              isOpen={isOpen54}
              onRequestClose={toggleFakurti}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleFakurti}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <Fakurti />
                </div>
              </div>
            </Modal>
            {/* End  FakurtiContent */}
            </li>
            {/* <!-- Fakurti Item End --> */}
            {/* <!-- Lavita Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleLavita}
            >
              <img src="img/projects/webDesign/11.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  LaVita Ristorante
                </span>
              </div>
            </figure>

            {/* Start LavitaContent */}
            <Modal
              isOpen={isOpen59}
              onRequestClose={toggleLavita}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleLavita}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <Lavita />
                </div>
              </div>
            </Modal>
            {/* End  LavitaContent */}
            </li>
            {/* <!-- Lavita Item End --> */}

            {/* <!-- LamaHausmeister Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleLamaHausmeister}
            >
              <img src="img/projects/webDesign/12.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Lama Hausmeister
                </span>
              </div>
            </figure>

            {/* Start LamaHausmeisterContent */}
            <Modal
              isOpen={isOpen58}
              onRequestClose={toggleLamaHausmeister}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleLamaHausmeister}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <LamaHausmeister />
                </div>
              </div>
            </Modal>
            {/* End  LamaHausmeisterContent */}
            </li>
            {/* <!-- LamaHausmeister Item End --> */}

            {/* <!-- Ilyonis Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleIlyonis}
            >
              <img src="img/projects/webDesign/13.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Ilyonis Construction
                </span>
              </div>
            </figure>

            {/* Start IlyonisContent */}
            <Modal
              isOpen={isOpen56}
              onRequestClose={toggleIlyonis}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleIlyonis}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <Ilyonis />
                </div>
              </div>
            </Modal>
            {/* End  IlyonisContent */}
            </li>
            {/* <!-- Ilyonis Item End --> */}
            {/* <!-- Albamarkt Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleAlbamarkt}
            >
              <img src="img/projects/webDesign/14.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Albamarkt
                </span>
              </div>
            </figure>

            {/* Start AlbamarktContent */}
            <Modal
              isOpen={isOpen51}
              onRequestClose={toggleAlbamarkt}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleAlbamarkt}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <Albamarkt />
                </div>
              </div>
            </Modal>
            {/* End  AlbamarktContent */}
            </li>
            {/* <!-- Albamarkt Item End --> */}
            {/* <!-- NovaGroup Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleNovaGroup}
            >
              <img src="img/projects/webDesign/15.png" alt="Portolio" />
              <div className=" direction-reveal__overlay direction-reveal__anim--enter">
                <span className="direction-reveal__title">
                  Nova Group Estate
                </span>
              </div>
            </figure>

            {/* Start NovaGroupContent */}
            <Modal
              isOpen={isOpen61}
              onRequestClose={toggleNovaGroup}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleNovaGroup}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <NovaGroup />
                </div>
              </div>
            </Modal>
            {/* End  NovaGroupContent */}
            </li>
            {/* <!-- NovaGroup Item End --> */}
          </ul>
        </TabPanel>

        <TabPanel>
          <ul className="row grid justify-content-center padding-ab">
             
            {/* <!-- Brand Identity Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalBrandOne}
              >
                <img src="img/projects/brand/layla/1.jpg" alt="Portolio" />
                
              </figure>

              {/* Start Brand One Start */}
              <Modal
                isOpen={isOpen300}
                onRequestClose={toggleModalBrandOne}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalBrandOne}>
                    <img src="/img/cancel.png" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalBrandOne />
                  </div>
                </div>
              </Modal>
              {/* End  Brand One End */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

           {/* <!-- Brand Identity Item Starts --> */}
           <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleModalBrandTwo}
            >
              <img src="img/projects/brand/teatroman/1.jpg" alt="Portolio" />
            </figure>

            {/* Start Brand Two */}
            <Modal
              isOpen={isOpen301}
              onRequestClose={toggleModalBrandTwo}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleModalBrandTwo}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <ModalBrandTwo />
                </div>
              </div>
            </Modal>
            {/* End  Brand Two */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleModalBrandThree}
            >
              <img src="img/projects/brand/mada/1.jpg" alt="Portolio" />
            </figure>

            {/* Start ModalBrandThree */}
            <Modal
              isOpen={isOpen302}
              onRequestClose={toggleModalBrandThree}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleModalBrandThree}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <ModalBrandThree />
                </div>
              </div>
            </Modal>
            {/* End  ModalBrandThree  */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleModalBrandFour}
            >
              <img src="img/projects/brand/woodcraft/1.jpg" alt="Portolio" />
            </figure>

            {/* Start ModalBrandFour */}
            <Modal
              isOpen={isOpen303}
              onRequestClose={toggleModalBrandFour}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleModalBrandFour}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <ModalBrandFour />
                </div>
              </div>
            </Modal>
            {/* End  ModalBrandFour  */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleModalBrandFive}
            >
              <img src="img/projects/brand/toka/1.jpg" alt="Portolio" />

            </figure>

            {/* Start ModalBrandFive */}
            <Modal
              isOpen={isOpen304}
              onRequestClose={toggleModalBrandFive}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleModalBrandFive}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <ModalBrandFive />
                </div>
              </div>
            </Modal>
            {/* End  ModalBrandFive  */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleModalBrandSix}
            >
              <img src="img/projects/brand/passion/1.jpg" alt="Portolio" />
            </figure>

            {/* Start ModalBrandSix */}
            <Modal
              isOpen={isOpen305}
              onRequestClose={toggleModalBrandSix}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleModalBrandSix}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <ModalBrandSix />
                </div>
              </div>
            </Modal>
            {/* End  ModalBrandSix  */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}
            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
            className="direction-reveal__card"
            onClick={toggleModalBrandSeven}
            >
            <img src="img/projects/brand/ilyonis/1.jpg" alt="Portolio" />
            </figure>

            {/* Start ModalBrandSeven */}
            <Modal
            isOpen={isOpen306}
            onRequestClose={toggleModalBrandSeven}
            contentLabel="My dialog"
            className="custom-modal dark"
            overlayClassName="custom-overlay dark"
            closeTimeoutMS={500}
            >
            <div>
                <button className="close-modal" onClick={toggleModalBrandSeven}>
                <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                <ModalBrandSeven />
                </div>
            </div>
            </Modal>
            {/* End  ModalBrandSeven  */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
            className="direction-reveal__card"
            onClick={toggleModalBrandEight}
            >
            <img src="img/projects/brand/morina/1.jpg" alt="Portolio" />
            </figure>

            {/* Start ModalBrandEight */}
            <Modal
            isOpen={isOpen307}
            onRequestClose={toggleModalBrandEight}
            contentLabel="My dialog"
            className="custom-modal dark"
            overlayClassName="custom-overlay dark"
            closeTimeoutMS={500}
            >
            <div>
                <button className="close-modal" onClick={toggleModalBrandEight}>
                <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                <ModalBrandEight />
                </div>
            </div>
            </Modal>
            {/* End  ModalBrandEight  */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
            className="direction-reveal__card"
            onClick={toggleModalBrandNine}
            >
            <img src="img/projects/brand/hotelballkan/1.jpg" alt="Portolio" />
            </figure>

            {/* Start ModalBrandNine */}
            <Modal
            isOpen={isOpen308}
            onRequestClose={toggleModalBrandNine}
            contentLabel="My dialog"
            className="custom-modal dark"
            overlayClassName="custom-overlay dark"
            closeTimeoutMS={500}
            >
            <div>
                <button className="close-modal" onClick={toggleModalBrandNine}>
                <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                <ModalBrandNine />
                </div>
            </div>
            </Modal>
            {/* End  ModalBrandNine  */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}
                        {/* <!-- Brand Identity Item Starts --> */}
                        <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
            className="direction-reveal__card"
            onClick={toggleModalBrandTen}
            >
            <img src="img/projects/brand/lama/1.jpg" alt="Portolio" />
            </figure>

            {/* Start ModalBrandTen */}
            <Modal
            isOpen={isOpen309}
            onRequestClose={toggleModalBrandTen}
            contentLabel="My dialog"
            className="custom-modal dark"
            overlayClassName="custom-overlay dark"
            closeTimeoutMS={500}
            >
            <div>
                <button className="close-modal" onClick={toggleModalBrandTen}>
                <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                <ModalBrandTen />
                </div>
            </div>
            </Modal>
            {/* End  ModalBrandTen  */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
            className="direction-reveal__card"
            onClick={toggleModalBrandEleven}
            >
            <img src="img/projects/brand/healthmedic/1.jpg" alt="Portolio" />
            </figure>

            {/* Start ModalBrandEleven */}
            <Modal
            isOpen={isOpen310}
            onRequestClose={toggleModalBrandEleven}
            contentLabel="My dialog"
            className="custom-modal dark"
            overlayClassName="custom-overlay dark"
            closeTimeoutMS={500}
            >
            <div>
                <button className="close-modal" onClick={toggleModalBrandEleven}>
                <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                <ModalBrandEleven />
                </div>
            </div>
            </Modal>
            {/* End  ModalBrandEleven  */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
            className="direction-reveal__card"
            onClick={toggleModalBrandTwelve}
            >
            <img src="img/projects/brand/luxury/1.jpg" alt="Portolio" />
            </figure>

            {/* Start ModalBrandTwelve */}
            <Modal
            isOpen={isOpen311}
            onRequestClose={toggleModalBrandTwelve}
            contentLabel="My dialog"
            className="custom-modal dark"
            overlayClassName="custom-overlay dark"
            closeTimeoutMS={500}
            >
            <div>
                <button className="close-modal" onClick={toggleModalBrandTwelve}>
                <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                <ModalBrandTwelve />
                </div>
            </div>
            </Modal>
            {/* End  ModalBrandTwelve  */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleModalBrandThirteen}
            >
              <img src="img/projects/brand/innovaspa/1.jpg" alt="Portolio" />
              
            </figure>

            {/* Start Brand Thirteen Start */}
            <Modal
              isOpen={isOpen312}
              onRequestClose={toggleModalBrandThirteen}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleModalBrandThirteen}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <ModalBrandThirteen />
                </div>
              </div>
            </Modal>
            {/* End  Brand Thirteen End */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleModalBrandFourteen}
            >
              <img src="img/projects/brand/laurabeauty/1.jpg" alt="Portolio" />
              
            </figure>

            {/* Start Brand Thirteen Start */}
            <Modal
              isOpen={isOpen313}
              onRequestClose={toggleModalBrandFourteen}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleModalBrandFourteen}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <ModalBrandFourteen />
                </div>
              </div>
            </Modal>
            {/* End  Brand Thirteen End */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}

            {/* <!-- Brand Identity Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleModalBrandFiveteen}
            >
              <img src="img/projects/brand/silverline/1.jpg" alt="Portolio" />
              
            </figure>

            {/* Start Brand Thirteen Start */}
            <Modal
              isOpen={isOpen314}
              onRequestClose={toggleModalBrandFiveteen}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleModalBrandFiveteen}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <ModalBrandFiveteen />
                </div>
              </div>
            </Modal>
            {/* End  Brand Thirteen End */}
            </li>
            {/* <!-- Brand Identity Item Ends --> */}
          </ul>
        </TabPanel>
        {/* End Brand Identity  */}

        <TabPanel>
          <ul className="row grid justify-content-center padding-ab">
            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleVideoOne}
              >
                <img src="img/projects/multimedia/1.jpg" alt="Portolio" />
                {/* Start VideoOne */}
                <Modal
                  isOpen={isOpen200}
                  onRequestClose={toggleVideoOne}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button className="close-modal" onClick={toggleVideoOne}>
                      <img src="/img/cancel.png" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalVideoOne />
                    </div>
                  </div>
                </Modal>
                {/* End  VideoOne */}
              </figure>
            </li>
            {/* <!-- Portfolio Item Ends --> */}
            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleVideoTwo}
              >
                <img src="img/projects/multimedia/2.jpg" alt="Portolio" />
                {/* Start Video2 */}
                <Modal
                  isOpen={isOpen201}
                  onRequestClose={toggleVideoTwo}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button className="close-modal" onClick={toggleVideoTwo}>
                      <img src="/img/cancel.png" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalVideoTwo />
                    </div>
                  </div>
                </Modal>
                {/* End  Video2 */}
              </figure>
            </li>
            {/* <!-- Portfolio Item Ends --> */}
            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleVideoThree}
              >
                <img src="img/projects/multimedia/3.jpg" alt="Portolio" />
                {/* Start VideoThree */}
                <Modal
                  isOpen={isOpen202}
                  onRequestClose={toggleVideoThree}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button className="close-modal" onClick={toggleVideoThree}>
                      <img src="/img/cancel.png" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalVideoThree />
                    </div>
                  </div>
                </Modal>
                {/* End  VideoThree */}
              </figure>
            </li>
            {/* <!-- Portfolio Item Ends --> */}
            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleVideoNine}
              >
                <img src="img/projects/multimedia/4.jpg" alt="Portolio" />
                {/* Start VideoNine */}
                <Modal
                  isOpen={isOpen208}
                  onRequestClose={toggleVideoNine}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button className="close-modal" onClick={toggleVideoNine}>
                      <img src="/img/cancel.png" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalVideoNine />
                    </div>
                  </div>
                </Modal>
                {/* End  VideoNine */}
              </figure>
            </li>
            {/* <!-- Portfolio Item Ends --> */}
            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleVideoSeven}
              >
                <img src="img/projects/multimedia/5.jpg" alt="Portolio" />
                {/* Start VideoSeven */}
                <Modal
                  isOpen={isOpen206}
                  onRequestClose={toggleVideoSeven}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button className="close-modal" onClick={toggleVideoSeven}>
                      <img src="/img/cancel.png" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalVideoSeven />
                    </div>
                  </div>
                </Modal>
                {/* End  VideoSeven */}
              </figure>
            </li>
            {/* <!-- Portfolio Item Ends --> */}
            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleVideoFive}
              >
                <img src="img/projects/multimedia/6.jpg" alt="Portolio" />
                {/* Start VideoFive */}
                <Modal
                  isOpen={isOpen204}
                  onRequestClose={toggleVideoFive}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button className="close-modal" onClick={toggleVideoFive}>
                      <img src="/img/cancel.png" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalVideoFive />
                    </div>
                  </div>
                </Modal>
                {/* End  Video5 */}
              </figure>
            </li>
            {/* <!-- Portfolio Item Ends --> */}
            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleVideoEight}
              >
                <img src="img/projects/multimedia/7.jpg" alt="Portolio" />
                {/* Start VideoEight */}
                <Modal
                  isOpen={isOpen207}
                  onRequestClose={toggleVideoEight}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button className="close-modal" onClick={toggleVideoEight}>
                      <img src="/img/cancel.png" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalVideoEight />
                    </div>
                  </div>
                </Modal>
                {/* End  VideoEight */}
              </figure>
            </li>
            {/* <!-- Portfolio Item Ends --> */}
            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleVideoFour}
              >
                <img src="img/projects/multimedia/8.jpg" alt="Portolio" />
                {/* Start VideoFour */}
                <Modal
                  isOpen={isOpen203}
                  onRequestClose={toggleVideoFour}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button className="close-modal" onClick={toggleVideoFour}>
                      <img src="/img/cancel.png" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalVideoFour />
                    </div>
                  </div>
                </Modal>
                {/* End  VideoFour */}
              </figure>
            </li>
            {/* <!-- Portfolio Item Ends --> */}
            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleVideoSix}
              >
                <img src="img/projects/multimedia/9.jpg" alt="Portolio" />

                {/* Start VideoSix */}
                <Modal
                  isOpen={isOpen205}
                  onRequestClose={toggleVideoSix}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
                >
                  <div>
                    <button className="close-modal" onClick={toggleVideoSix}>
                      <img src="/img/cancel.png" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner portfolio">
                      <ModalVideoSix />
                    </div>
                  </div>
                </Modal>
                {/* End  VideoSix */}
              </figure>
            </li>
            {/* <!-- Portfolio Item Ends --> */}
          </ul>
        </TabPanel>
        {/* End Graphic Design Project*/}

        <TabPanel>
          <ul className="row grid justify-content-center padding-ab">
            {/* <!-- Template1 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateOne}
            >
              <img src="img/projects/templates/1.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen101}
              onRequestClose={toggleTemplateOne}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateOne}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateOne />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template1 Item End --> */}


            {/* <!-- Template2 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateTwo}
            >
              <img src="img/projects/templates/2.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen102}
              onRequestClose={toggleTemplateTwo}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateTwo}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateTwo />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template2 Item End --> */}

            {/* <!-- Template3 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateThree}
            >
              <img src="img/projects/templates/3.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen103}
              onRequestClose={toggleTemplateThree}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateThree}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateThree />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template3 Item End --> */}

            {/* <!-- Template4 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateFour}
            >
              <img src="img/projects/templates/4.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen104}
              onRequestClose={toggleTemplateFour}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateFour}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateFour />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template4 Item End --> */}

            {/* <!-- Template5 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateFive}
            >
              <img src="img/projects/templates/5.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen105}
              onRequestClose={toggleTemplateFive}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateFive}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateFive />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template5 Item End --> */}

            {/* <!-- Template6 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateSix}
            >
              <img src="img/projects/templates/6.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen106}
              onRequestClose={toggleTemplateSix}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateSix}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateSix />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template6 Item End --> */}

            {/* <!-- Template7 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateSeven}
            >
              <img src="img/projects/templates/7.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen107}
              onRequestClose={toggleTemplateSeven}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateSeven}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateSeven />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template7 Item End --> */}

            {/* <!-- Template8 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateEight}
            >
              <img src="img/projects/templates/8.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen108}
              onRequestClose={toggleTemplateEight}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateEight}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateEight />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template8 Item End --> */}

            {/* <!-- Template9 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateNine}
            >
              <img src="img/projects/templates/9.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen109}
              onRequestClose={toggleTemplateNine}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateNine}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateNine />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template9 Item End --> */}
            {/* <!-- Template10 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateTen}
            >
              <img src="img/projects/templates/10.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen110}
              onRequestClose={toggleTemplateTen}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateTen}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateTen />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template10 Item End --> */}

            {/* <!-- Template11 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateEleven}
            >
              <img src="img/projects/templates/11.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen111}
              onRequestClose={toggleTemplateEleven}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateEleven}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateEleven />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template11 Item End --> */}

            {/* <!-- Template12 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateTwelve}
            >
              <img src="img/projects/templates/12.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen112}
              onRequestClose={toggleTemplateTwelve}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateTwelve}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateTwelve />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template12 Item End --> */}
            {/* <!-- Template13 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateThirteen}
            >
              <img src="img/projects/templates/13.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen113}
              onRequestClose={toggleTemplateThirteen}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateThirteen}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateThirteen />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template13 Item End --> */}

            {/* <!-- Template14 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateFourteen}
            >
              <img src="img/projects/templates/14.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen114}
              onRequestClose={toggleTemplateFourteen}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateFourteen}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateFourteen />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template14 Item End --> */}

            {/* <!-- Template15 Item Starts --> */}
            <li
            className="direction-reveal"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            >
            <figure
              className="direction-reveal__card"
              onClick={toggleTemplateFiveteen}
            >
              <img src="img/projects/templates/15.jpg" alt="Portolio" />
              
            </figure>

            {/* Start TemplateContent */}
            <Modal
              isOpen={isOpen115}
              onRequestClose={toggleTemplateFiveteen}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
            >
              <div>
                <button className="close-modal" onClick={toggleTemplateFiveteen}>
                  <img src="/img/cancel.png" alt="close icon" />
                </button>
                {/* End close icon */}

                <div className="box_inner portfolio">
                  <TemplateFiveteen />
                </div>
              </div>
            </Modal>
            {/* End  TemplateContent */}
            </li>
            {/* <!-- Template15 Item End --> */}
          </ul>
        </TabPanel>
        {/* End MOCKUP Project */}
      </div>
    </Tabs>
  );
};

export default Portfolio;

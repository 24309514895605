import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Row } from './layout/Row'
import { Col } from './layout/Col'
import {
  CenteredContent,
  FileInputLabel,
  Header,
  HiddenInput,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledSelect,
  StyledSelectItem,
  StyledTextarea,
  SubHeader,
  Submit
} from './ContactForm.styles'
import { useEffect, useState } from 'react'

/**
 * "How did you hear about us ?" options
 */
const selectOptions = [
  'Google',
  'SortList',
  'Clutch',
  'Social Media',
  'Advertising',
  'Other'
]

/**
 * Setup API client
 */
const API = axios.create({
  baseURL: 'https://studio.ablimits.com:5000', // Change this url before building with the one used in production (f.e.: 'https://mywebsite.com')
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

export const ContactForm = (props) => {
  const { register, handleSubmit } = useForm({})
  const [origins, setOrigins] = useState([])
  const [isAttachment, setIsAttachment] = useState(false)

  useEffect(() => {
    // Trigger fake resize event to prevent select dropdown taking the whole window width
    window.dispatchEvent(new Event('resize'))
  }, [])

  async function onSubmit (data) {
    const basicFields = ['firstName', 'lastName', 'email', 'phone', 'company', 'details', 'newsletter']
    const { attachment, ...rest } = data
    try {
      const formData = new FormData()
      // Handle attachment
      formData.append('attachment', attachment[0])
      // Handle origins array
      origins.forEach((origin) => formData.append('origins', origin.value))
      for (const key in rest) {
        if (basicFields.includes(key)) {
          // Add basic keys
          formData.append(key, data[key])
        } else {
          // Transform project types as array
          data[key] && formData.append('projectType', key)
        }
      }
      // Send form to API
      await API.post('/', formData)
      props.onSuccess.close()
      props.onSuccess.toast()
    } catch (e) {
      console.error('Could not send form', e)
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <CenteredContent>
        <Header>REQUEST A PROPOSAL</Header>
        <SubHeader><i>Fill Out Our Form & We'll be in Touch Shortly</i></SubHeader>
        <Row>
          <Col md={6}>
            <StyledInput {...register('firstName', { required: true })} placeholder="First Name *" type="text"/>
          </Col>
          <Col md={6}>
            <StyledInput {...register('lastName', { required: true })} placeholder="Last Name *" type="text"/>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <StyledInput {...register('email', { required: true })} placeholder="Email *" type="email"/>
          </Col>
          <Col md={6}>
            <StyledInput {...register('phone', { required: true })} placeholder="Phone *" type="tel"/>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <StyledInput {...register('company', { required: true })} placeholder="Company *" type="text"/>
          </Col>
          <Col md={6}>
            <StyledSelect
              options={selectOptions.map((opt) => ({ label: opt, value: opt }))}
              values={[]}
              searchable={false}
              placeholder="How did you hear about us?"
              itemRenderer={({ item, methods }) => (
                <StyledSelectItem>
                  <div onClick={() => methods.addItem(item)}>
                    <input
                      onChange={() => methods.addItem(item)}
                      type="checkbox"
                      checked={methods.isSelected(item)}
                    />
                    <span style={{ color: methods.isSelected(item) ? '#fff' : 'inherit' }}>{item.label}</span>
                  </div>
                </StyledSelectItem>
              )}
              onChange={setOrigins}
            />
          </Col>
        </Row>
      </CenteredContent>
      <SubHeader style={{ marginTop: 20 }}>TYPE OF PROJECT *</SubHeader>
      <Row>
        <Col md={6}>
          <input {...register('website')} id="website" type="checkbox"/>
          <StyledLabel htmlFor="website">Website</StyledLabel>
        </Col>
        <Col md={6}>
          <input {...register('video')} id="video" type="checkbox"/>
          <StyledLabel htmlFor="video">Video</StyledLabel>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <input {...register('branding')} id="branding" type="checkbox"/>
          <StyledLabel htmlFor="branding">Branding</StyledLabel>
        </Col>
        <Col md={6}>
          <input {...register('seo')} id="seo" type="checkbox"/>
          <StyledLabel htmlFor="seo">SEO</StyledLabel>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <input {...register('digitalMarketing')} id="digitalMarketing" type="checkbox"/>
          <StyledLabel htmlFor="digitalMarketing">Digital Marketing</StyledLabel>
        </Col>
        <Col md={6}>
          <input {...register('mobileApp')} id="mobileApp" type="checkbox"/>
          <StyledLabel htmlFor="mobileApp">Mobile App</StyledLabel>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <StyledTextarea rows={6} placeholder="Project Details *" {...register('details', { required: true })} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FileInputLabel  isattachment={isAttachment}>
            <HiddenInput {...register('attachment', { onChange: () => setIsAttachment(true) })} type="file"/>
            <span style={{ marginRight: 6 }}>📎</span> {isAttachment ? 'Change attachment' : 'Add an attachment'}
          </FileInputLabel>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col className="signUpNewsletter" md={12}>
          <input {...register('newsletter')} id="newsletter" type="checkbox"/>
          <StyledLabel htmlFor="newsletter" h>Sign me up for Newsletter. Get monthly marketing, web, mobile tips and
            insight.</StyledLabel>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col md={12}>
          <Submit type="submit">
            <span>
              Submit
            </span>
          </Submit>
        </Col>
      </Row>
    </StyledForm>
  )
}
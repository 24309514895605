import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Hero from "../../components/hero/Hero";
import Index from "../../components/about/index";
import Portfolio from "../../components/portfolio/Portfolio";
import Blog from "../../components/blog/Blog";
import VideoAnimation from "../../components/videoanim/VideoAnimation";
import logo from "./logoab.png";
import Modal from "react-modal";
import {ContactFormContainer} from "./ContactForm/ContactFormContainer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoMobile from "./logoMobile.png";

const menuItem = [
  { icon: "fab fa-gitkraken", menuName: "Studio" },
  { icon: "fas fa-play", menuName: "Intro" },
  { icon: "fas fa-photo-video", menuName: "Portfolio" },
  { icon: "fas fa-film", menuName: "Animation" },
  { icon: "fas fa-quote-right", menuName: "Blog" },
  { icon: "fas fa-solid fa-info", menuName: "Imprint"}
];


const HomeDark = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleContactForm() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="yellow">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black"
        />
      </Helmet>
      <div id="scroll" className="demo-sticker">
        <a href="https://ablimits.com">
          <img src={logo}  alt="Desktop Logo" className="ab-logo ablogo-desktop"></img>
          <img src={logoMobile}  alt="mobile Logo" className="ab-logo ablogo-mobile"></img>
        </a>
        {/*  <div className="mds-c-example" onClick={toggleContactForm}>
        <svg className="mds-c-icon mds-i-bell mds-i-bell--animated" viewBox="0 0 16 16">
          <g className="mds-i-bell__bell">
            <path className="mds-i-bell__clapper" d="M7 12C7.55228 12 8 12.4477 8 13C8 12.4477 8.44772 12 9 12C9.55228 12 10 12.4477 10 13C10 13.5272 9.81989 14.0443 9.43211 14.4321C9.04433 14.8199 8.52725 15 8 15C7.47275 15 6.95567 14.8199 6.56789 14.4321C6.18011 14.0443 6 13.5272 6 13C6 12.4477 6.44772 12 7 12Z" />
            <path className="mds-i-bell__shell" d="M2 7C2 3.64772 4.64772 1 8 1C11.3523 1 14 3.64772 14 7V11C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H2C1.44772 13 1 12.5523 1 12C1 11.4477 1.44772 11 2 11V7ZM4 11H12V7C12 4.75228 10.2477 3 8 3C5.75228 3 4 4.75228 4 7V11Z" />
          </g>
          <g className="mds-i-bell__badge">
          <circle className="mds-c-icon__badge-dot" cx="11" cy="3" r="3" />
          <circle className="mds-c-icon__badge-ripple" cx="11" cy="3" r="3" />
          </g>
        </svg>
        <h1 id="contactText" className="display-none-mb">
        <span>R</span>
        <span>E</span>
        <span>Q</span>
        <span>U</span>
        <span>E</span>
        <span>S</span>
        <span class="space">T</span>
        <span class="space">A</span>
        <span>P</span>
        <span>R</span>
        <span>O</span>
        <span>P</span>
        <span>O</span>
        <span>S</span>
        <span>A</span>
        <span class="pace">L</span>
        <span>?</span>

        </h1>
      </div> */}
      </div>
      <Tabs>
        <div className="header">
          <TabList className=" icon-menu  revealator-slideup revealator-once revealator-delay1">
            {menuItem.map((item, i) => (
              <Tab className="icon-box" key={i}>
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Tab>
            ))}
          </TabList>

        </div>
        {/* End Menu Content */}

        <div className="tab-panel_list">
          {/* Hero Content Starts */}
          <TabPanel className="home ">
            <div
              className="container-fluid main-container container-home p-0 "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              
              <Hero />
            </div>
          </TabPanel>
          {/* Hero Content Ends */}


          {/* About Content Starts */}
          <TabPanel className="about">
            <div data-aos="fade-up" data-aos-duration="1500">
              <Index />
            </div>
          </TabPanel>
          {/* About Content Ends */}

          {/* Portfolio Content Starts */}
          <TabPanel className="portfolio professional">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h1>
                our <span>portfolio</span>
              </h1>
              <span className="title-bg">works</span>
            </div>
            {/* End title */}
            <div
              className="container grid-gallery main-content"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <Portfolio />
            </div>
            {/* End grid gallery */}
          </TabPanel>
          {/* Portfolio Content Ends */}

          {/* Video Animation Content Starts */}
          <TabPanel className="vidanimation">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                Logo <span>Animation</span>
              </h1>
              <span className="title-bg">MultiMedia</span>
            </div>
            {/* End title */}
            <div
              className="container grid-gallery main-content"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              <VideoAnimation />
            </div>
            {/* End grid gallery */}
          </TabPanel>
          {/* Video Animation Content Ends */}

          {/* Blog Content Starts */}
          <TabPanel className="blog">
            <div
              className="title-section text-left text-sm-center "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                NEWS <span>LETTER</span>
              </h1>
              <span className="title-bg">posts</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              {/*  Articles Starts  */}
              <div className="row pb-50">
                <Blog />
              </div>
              {/* Articles Ends */}
            </div>
          </TabPanel>
          {/* Blog Content Ends */}


           {/* Imprint Starts */}
           <TabPanel className="imprint">
            <div
              className="title-section text-left text-sm-center "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
               Legal <span>Information</span>
              </h1>
              <span className="title-bg">IMPRINT</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              {/*  Articles Starts  */}
              <div className="row pb-50">
      <p>Company Information:</p>
      <p>
        ABlimits SH.P.K <br/>
        Rr, Kongresi i Manastirit 438/2, <br/>
        1001 Tirana<br/>
        Albania
      </p>
      <p>
        Domicile: Tirana<br/>
        Commercial Register: National Business Center<br/>
        ID-Nr.: M02318014Q<br/>
        Serial-Nr.: SN-704623-11-20
      </p>
      <p>
        Co. Founder/CEO: A. Hysa & B. Hysa<br/>
        Administrator: B. Hysa
      </p>
      <p>
        Tel: AL +(355) 69 40 80 200 <br/>
        E-mail: info@ablimits.com<br/>
        Internet: www.ablimits.com<br/>
        Internet: www.ablimits.eu<br/>
      </p>
      <p>For settling consumer disputes out-of-court, the European Union offers a platform for online dispute resolution (“ODR platform“) at <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>. <br/>We are neither willing nor legally obligated to settle disputes on an ODR platform.</p>
      <p>This imprint is also valid for:</p>
      <p>
        Instagram: <a href="https://www.instagram.com/ablimits/">https://www.instagram.com/ablimits/</a><br/>
        Youtube: <a href="https://www.youtube.com/channel/UCI2NzR3GiusKMWWlKjUxFMA">ABlimits Studio</a>
      </p>
      <hr/>
      <h2>Privacy Policy</h2>
      <h3>What information do we collect?</h3>
      <p>We only collect personally identifiable information from you when you enter information into our form fields. You may be asked to enter your: company name, e-mail address, mailing address or phone number.</p>

      <h3>What do we use your information for?</h3>
      <p>Any of the information we collect from you may be used in one of the following ways:</p>

      <p>
        <strong>To personalize your experience</strong><br/>
        (your information helps us to better respond to your individual needs)<br/>

        <strong>To improve our website</strong><br/>
        (we continually strive to improve our website offerings based on the information and feedback we receive from you)<br/>

        <strong>To improve customer service</strong><br/>
        (your information helps us to more effectively respond to your customer service requests and support needs)<br/>

        <strong>To send periodic emails</strong><br/>
        (the email address you provide may be used by automated, trigger-based email campaigns to send you information, respond to inquiries, and/or other requests or questions)<br/>

        <strong>To contact you</strong><br/>
        (if you request that we contact you)<br/>
      </p>

      <h3>How do we protect your information?</h3>
      <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. Your information is stored in a password protected database.</p>

      <h3>Do we use cookies?</h3>
      <p>Yes, Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information. 
        We use cookies to help us remember, understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. This information is collected in an anonymous form that includes no personally identifiable information. No personally identifiable information is collected on this website unless that information is explicitly submitted via a fill-in form. For example, an email address submission is requested in order to Work With Us on <a href="http://www.ablimits.com/">http://www.ablimits.com/</a> and users will have the option to provide this information or not.
        This anonymous information generated by cookies is transmitted to Google where it is processed to generate anonymous, aggregate reporting on website activity strictly for the domain <a href="http://www.ablimits.com/">http://www.ablimits.com/</a>. These reports are used to gain insight on how best to optimize the user experience, content, and marketing at <a href="http://www.ablimits.com/">http://www.ablimits.com/</a>.
      </p>

      <h3>Do we disclose any information to outside parties?</h3>
      <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>

      <h3>Third party links</h3>
      <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>

      <h3>Online Privacy Policy Only</h3>
      <p>This online privacy policy applies only to information collected through our website and not to information collected offline.</p>

      <h3>Your Consent</h3>
      <p>By using our site, you consent to our websites privacy policy.</p>

      <h3>Changes to our Privacy Policy</h3>
      <p>If we decide to change our privacy policy, we will post those changes on this page.</p>

      <h3>Contacting Us</h3>
      <p>If there are any questions regarding this privacy policy you may contact us using the information below.</p>

      <strong>Website:</strong><br/>
      www.ablimits.com<br/><br/>

      <strong>Address:</strong><br/>
      Rr, Kongresi i Manastirit 438/2,<br/>
      1001 Tirana, Albania<br/><br/>

      <strong>E-mail:</strong><br/>
      info@ablimits.com<br/><br/>

      <strong>Phone:</strong><br/>
      +(355) 69 40 80 200<br/>
              </div>
              {/* Articles Ends */}
            </div>
          </TabPanel>
          {/* Imprint Ends */}


        </div>
      </Tabs>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleContactForm}
        contentLabel="My dialog"
        className="dark hero test-class"
        overlayClassName="custom-overlay dark"
        closeTimeoutMS={500}
      >
        <div data-aos="fade-left" data-aos-duration="5200">
          <div className="box_inner about">
            <div className="scrollable-modal">
            <ContactFormContainer onSuccess={() => toast.success('Successfully sent form')} onClose={toggleContactForm}/>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        theme='dark'
      />
    </div>
    
  );
}

export default HomeDark;

import React from 'react'
import styled, { css } from 'styled-components'
import { Row as GridRow, useScreenClass } from 'react-grid-system'

const StyledRow = styled(GridRow)`
  ${props => props.screenclass && css`
    margin-top: ${['xs', 'sm'].includes(props.screenclass) ? 0 : 10}px;
  `}
`

/**
 * Wraps default Row to remove top-margin when device is xs/sm
 */
export const Row = ({ children, ...props }) => {
  const screenClass = useScreenClass()

  return (
    <StyledRow screenclass={screenClass} {...props}>
      {children}
    </StyledRow>
  )
}
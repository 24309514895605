
const TemplateNine = () => {
    return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>Shopify E-Commerce</h3>
            <div className="row open-sans-font">
              <div className="col-12 mb-2">
              <i class="fab fa-shopify"></i>
                <span className="ft-wt-600 uppercase">  Is no doubt a top-notch Shopify platform to embellish an eCommerce website that actually brings sales. Theme ensures best quality website design and rich functionality. To generate sales from your website, you have to provide your customers with a handy website. </span>
              </div>
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}
  
          {/*  Main Project Content Starts */}
          <img src="img/projects/templates/9.jpg" alt="Portolio" />
          {/* Main Project Content Ends */}
        </figure>
      </div>
      //  End .slideshow
    );
  };
  
  export default TemplateNine;
  
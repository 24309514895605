
const ModalVideoEight = () => {
    return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>Multimedia Professional</h3>
            <div className="row open-sans-font">
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-file-text-o pr-2"></i>
                <span className="project-label">Project </span>:{" "}
                <span className="ft-wt-600 uppercase">Video</span>
              </div>
              {/* End .col */}
  
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-user-o pr-2"></i>
                <span className="project-label">Client </span>:{" "}
                <span className="ft-wt-600 uppercase">Reinigung Firma</span>
              </div>
              {/* End .col */}
  
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-code pr-2"></i>
                <span className="project-label">Software </span>:{" "}
                <span className="ft-wt-600 uppercase">Adobe After Effects</span>
              </div>
              {/* End .col */}
  
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-external-link pr-2"></i>
                <span className="project-label">Preview </span>:{" "} 
                <span className="ft-wt-600 uppercase">
                  <a
                    href="https://www.youtube.com/watch?v=kQMAlfIxnwk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Reinigung Firma
                  </a>
                </span>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}
  
          {/*  Main Project Content Starts */}
          <div className="videocontainer">
            <iframe
              className="youtube-video"
              title="youtube video"
              src="https://www.youtube.com/embed/kQMAlfIxnwk?enablejsapi=1&version=3&playerapiid=ytplayer"
              allowFullScreen
            ></iframe>
          </div>
          {/* Main Project Content Ends */}
        </figure>
      </div>
      //  End .slideshow
    );
  };
  
  export default ModalVideoEight;
  

const TemplateThirteen = () => {
    return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>Shopify E-Commerce</h3>
            <div className="row open-sans-font">
                <div className="col-12 mb-2">
                <i class="fab fa-shopify"></i>
                  <span className="ft-wt-600 uppercase"> Clean, pleasant, responsive theme for your Shopify store that stands out of the crowd. This stylish and fashionable Shopify theme is ideal for creating a shopping store, gift shop, mobile store, clothing store, or any fashion industry related shop or any other type of e-store.</span>
                </div>
              </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}
  
          {/*  Main Project Content Starts */}
          <img src="img/projects/templates/13.jpg" alt="Portolio" />
          {/* Main Project Content Ends */}
        </figure>
      </div>
      //  End .slideshow
    );
  };
  
  export default TemplateThirteen;
  
  
  
    
    
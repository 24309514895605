import { Container, setConfiguration } from 'react-grid-system'
import { ContactForm } from './ContactForm'
import { GradientContainer } from './ContactFormContainer.styles'

// Set container configuration for responsiveness
setConfiguration({
  containerWidths: [540, 740, 752, 752],
  breakpoints: [576, 768, 992, 1200]
})

export const ContactFormContainer = (props) => {
  return (
    <Container className="ContactContainer"
      style={{
        backgroundColor: 'rgb(17, 17, 17)',
        margin: '35px auto 35px',
        padding: '5px 5px'
      }}
    >
      <GradientContainer>
              <button className="close-modal" onClick={props.onClose}>
                <img src="/img/cancel.png" alt="close icon" />
              </button>
        <ContactForm onSuccess={{ toast: props.onSuccess, close: props.onClose}}/>
      </GradientContainer>
    </Container>
  )
}
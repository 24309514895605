
const Morina = () => {
    return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>Web Development</h3>
            <div className="row open-sans-font">
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-file-text-o pr-2"></i>
                <span className="project-label">Project </span>:{" "}
                <span className="ft-wt-600 uppercase">Website</span>
              </div>
              {/* End .col */}
  
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-user-o pr-2"></i>
                <span className="project-label">Client </span>:{" "}
                <span className="ft-wt-600 uppercase">Morina Dekor</span>
              </div>
              {/* End .col */}
  
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-code pr-2"></i>
                <span className="project-label">Platform </span>:{" "}
                <span className="ft-wt-600 uppercase">HTML</span>
              </div>
              {/* End .col */}
  
              <div className="col-12 col-sm-6 mb-2">
                <i className="fa fa-external-link pr-2"></i>
                <span className="project-label">Preview </span>:{" "} 
                <span className="ft-wt-600 uppercase">
                  <a
                    href=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    Morina Dekor
                  </a>
                </span>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}
  
          {/*  Main Project Content Starts */}
          <img src="img/projects/webDesign/7.png" alt="Portolio" className="portfolio-img-gradient" />
          {/* Main Project Content Ends */}
        </figure>
      </div>
      //  End .slideshow
    );
  };
  
  export default Morina;
  
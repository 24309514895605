import styled, { css } from 'styled-components'
import { Col as GridCol, useScreenClass } from 'react-grid-system'

const StyledCol = styled(GridCol)`
  ${props => props.screenclass && css`
    margin-top: ${['xs', 'sm'].includes(props.screenclass) ? 16 : 0}px;
  `}
`

/**
 * Wraps default Col to add top-margin when device is xs/sm
 */
export const Col = ({ children, ...props }) => {
  const screenClass = useScreenClass()

  return (
    <StyledCol screenclass={screenClass} {...props}>
      {children}
    </StyledCol>
  )
}

const TemplateFive = () => {
    return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>HTML TEMPLATE</h3>
            <div className="row open-sans-font">
              <div className="col-12 mb-2">
                <i class="fab fa-html5"></i>
                <span className="ft-wt-600 uppercase"> Modern startup and digital agency business template with a clean, contemporary and attention-grabbing design. The template design is driven by minimalist and unique sections. </span>
              </div>
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}
  
          {/*  Main Project Content Starts */}
          <img src="img/projects/templates/5.jpg" alt="Portolio" />
          {/* Main Project Content Ends */}
        </figure>
      </div>
      //  End .slideshow
    );
  };
  
  export default TemplateFive;
  
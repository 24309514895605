
const TemplateTwo = () => {
    return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>USER EXPERIENCE REACT.JS</h3>
            <div className="row open-sans-font">
              <div className="col-12 mb-2">
                <i class="fab fa-react"></i>
                <span className="ft-wt-600 uppercase"> Material Design Mobile Template compatible with all your needs, has two home page, shop, portfolio, blog, and others. with modern design, layout which is easy to use. This template is easy to edit, brings clean design, modern design, and many other features.</span>
              </div>
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}
  
          {/*  Main Project Content Starts */}
          <img src="img/projects/templates/2.jpg" alt="Portolio" />
          {/* Main Project Content Ends */}
        </figure>
      </div>
      //  End .slideshow
    );
  };
  
  export default TemplateTwo;
  
// Components
import Card from './Card';
// Styles
import { Wrapper } from './App.styles';

import logo5 from './img/company5.png';
import logo6 from './img/company6.png';
import logo7 from './img/company7.png';
import logo8 from './img/company8.png';
import logo9 from './img/company9.png';
import logo10 from './img/company10.png';
import logo11 from './img/company11.png';
import logo12 from './img/company12.png';
import logo13 from './img/company13.png';
import logo14 from './img/company14.png';
import logo15 from './img/company15.png';
import logo16 from './img/company16.png';


import video5 from './video/video5.mp4';
import video6 from './video/video6.mp4';
import video7 from './video/video7.mp4';
import video8 from './video/video8.mp4';
import video9 from './video/video9.mp4';
import video10 from './video/video10.mp4';
import video11 from './video/video11.mp4';
import video12 from './video/video12.mp4';
import video13 from './video/video13.mp4';
import video14 from './video/video14.mp4';
import video15 from './video/video15.mp4';
import video16 from './video/video16.mp4';


const VideoAnimation: React.FC = () => {
  return (
    <Wrapper>
      <Card logo={logo13} video={video13} />
      <Card logo={logo7} video={video7} />
      <Card logo={logo12} video={video12} />
      <Card logo={logo5} video={video5} />
      <Card logo={logo6} video={video6} />
      <Card logo={logo8} video={video8} />
      <Card logo={logo9} video={video9} />
      <Card logo={logo10} video={video10} />
      <Card logo={logo11} video={video11} />
      <Card logo={logo14} video={video14} />
      <Card logo={logo15} video={video15} />
      <Card logo={logo16} video={video16} />
    </Wrapper>
  );
}

export default VideoAnimation;

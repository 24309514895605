import React from "react";
import HomeDark from "../views/all-home-version/HomeDark";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
          <Route path="/" component={HomeDark} />
      </Router>
    </>
  );
};

export default Routes;

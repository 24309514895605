
const TemplateSix = () => {
    return (
      <div className="slideshow">
        <figure>
          {/* Project Details Starts */}
          <figcaption>
            <h3>E-Commerce</h3>
            <div className="row open-sans-font">
              <div className="col-12 mb-2">
              <i class="fab fa-shopify"></i>
                <span className="ft-wt-600 uppercase"> Online Shop Template suitable for those who want to build an online shop, fresh and clean design with having lots of features, instantly see the preview to see any features</span>
              </div>
            </div>
            {/* End .row */}
          </figcaption>
          {/* Project Details Ends */}
  
          {/*  Main Project Content Starts */}
          <img src="img/projects/templates/6.jpg" alt="Portolio" />
          {/* Main Project Content Ends */}
        </figure>
      </div>
      //  End .slideshow
    );
  };
  
  export default TemplateSix;
  
import React from "react";
import Cover3 from "../covers/2.gif";

const ModalTwoBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Post <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> ABlimits Studio
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 11 November 2021
        </span>
        <span>
          <i className="fa fa-tags"></i> seo, business, marketing, design
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>How to Best Optimize Your Website for SEO and Conversions</h1>
      <img src={Cover3} className="img-fluid" alt="Blog" />
      <div className="blog-excerpt open-sans-font">
        <p>
        This can be a critical component for anyone running their own site due to the fact that SEO can help bring some great traffic to your site without the heavy marketing costs associated with other methods, but then again it’s just as critical to get them to convert whether through a purchase or some other call to action. 
        </p>
        <strong>Enhance your SEO strategy</strong>
        <p>
        First and foremost is understanding briefly how SEO works. By having the right type of content on your site, the search engines out there will crawl your site for this relevant information and help you rank higher when someone decides to search for something on one of these search engines.
        </p>
        <p>
        That means that a lot of content will be needed, and it will be needed consistently. The best way to start is to handle the initial content of your site and make sure the keywords and verbiage that are everywhere relate to the topic you are trying to convey. In addition make sure the keywords (which are the main words you want the search engines looking for) are tied into your sites meta tags, which can easily be done by most website builders today.
        </p>
        <p>
        After that, it's all about generating content that is focused on your product and industry to start driving user traffic to your site. SEO takes around three to six months to start showing results, with the consistent effort being placed on delivering that unique content. Once that happens your site will be easily found by search engines and the users will start to discover you and see what your site is all about.
        </p>
        <strong>Build a site with conversions in mind</strong>
        <p>Now that you have a nice flow of traffic to your site, it's important to keep these users engaged. The best way to handle this is to not have an overcomplicated design that will put many users off who will then ultimately leave your site. It is all about simplicity and a clear path for a user, once they are on their site, to discover what you care about and get directed to that ultimate call to action (or CTA). Whether it be a sign-up form or a product purchase. </p>
        <p>That means the minute they hit your home page, or your blog page on the topic, or any page on your site for that matter, there is a clear way to continue their engagement after they have absorbed the initial content and use your CTA. </p>
        <div className="quotebox">
          <div className="icon">
            <img src={"img/blog/quote.svg"} alt="blog quote" />
          </div>
          <p>
          If you're looking for a helping hand in achieving an optimum SEO strategy, ABLIMITS are your go-to in achieving this.<br/> <a href="mailto:info@ablimits.com">Contact us today</a> for more details.
          </p>
        </div>
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalTwoBlogContent;

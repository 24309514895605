import React from "react";
import Cover4 from "../covers/4.gif";
import ablimitsClutch from "../covers/ablimitsctluch.png";
import ablimitsMorina from "../covers/ablimitsmorina.png";

const ModalFourBlogContent = () => {
  return (
    //  Article Starts
    <article>
      <div className="title-section text-left text-sm-center">
        <h1>
          Post <span>Details</span>
        </h1>
        <span className="title-bg">posts</span>
      </div>
      {/* Meta Starts */}

      <div className="meta open-sans-font">
        <span>
          <i className="fa fa-user"></i> ABlimits Studio
        </span>
        <span className="date">
          <i className="fa fa-calendar"></i> 22 December 2021
        </span>
        <span>
          <i className="fa fa-tags"></i> branding, b2b, clutch, design
        </span>
      </div>
      {/* Meta Ends */}
      {/* Article Content Starts */}

      <h1>ABLIMITS STUDIO Garners 5-Star Reviews on Clutch</h1>
      
      <div className="blog-excerpt open-sans-font">
        <p>
        Going digital means fine-tuning your <a href="https://whatis.techtarget.com/definition/Web-presence">web presence</a> and connecting with your audiences through bespoke content. 
        If you’re a business owner that’s just getting started, it can be a lot to handle and juggle.
        That’s where we come in to help! At ABLIMITS STUDIO, we handle everything from web design and web development to logo creation and digital marketing.
        </p>
        <p>
        Headquartered in Tirana, Albania, our team is a full-service digital agency with a mission to provide effective and efficient solutions for your unique needs. 
        With us, you can sit back zone in on your operations — we’ll handle the design and development works you have. 
        </p>
        <p>
          With over 6 years of industry experience, we’re guaranteed to have the best knowledge and skills out there. 
          Our team doesn’t settle for subpar outputs, we make sure that our track record is clean and outstanding.
        </p>
        <p>
          With that being said, ABLIMITS STUDIO is incredibly proud to announce that we’ve finally launched our vendor profile on Clutch!
           Our generous client helped us get started on the widely respected platform and provided us with a <a href="https://clutch.co/profile/ablimits-studio#reviews">great debut review</a>!
        </p>
        <img src={ablimitsClutch} />
        <p>
          Clutch is a B2B review and rating platform that focuses on publishing insightful content geared to help browsers from all over the world. 
          Past and present clients go to the platform to provide feedback regarding their collaboration with different vendors. 
          Getting started on the platform will significantly help us solidify our own reputation in the industry, that’s why we’re extremely excited.
        </p>
        <p>
          Our first client review was comprehensively written by the Chief Executive Officer of MORINA DEKOR, a construction company based in Germany. 
          Back in October 2020, we started working with the client to help construct a custom website, produce graphic materials, and design their logo.
        </p>
        <p>A three-man team from ABLIMITS STUDIO swiftly took care of MORINA DEKOR’s needs — and in just a couple of weeks, we delivered what they were looking for. In the results and feedback section, our client said that everything went perfectly and they were amazed by the team. </p>
        <p>“They were detailed, professional, and precise in the workflow.” — CEO, MORINA DEKOR</p>
        <img src={ablimitsMorina} />
        <p>
          Their fantastic feedback brings motivation to the ABLIMITS STUDIO team! We are absolutely thankful to our client for taking the time to review our services. 
          We are thrilled to have their insights showcase what we do, and we look forward to working with them again in the future. 
          Thank you so much for trusting us with this massive project!
        </p>
        <p>Aside from the amazing rapport we have with our dear clients, we’re also proud to share our newest recognition from Top Design Firms</p>
        <p>
          For those who aren’t familiar with them yet, Top Design Firms is a new B2B website that <a href="https://topdesignfirms.com/branding-companies">shows off industry leaders</a> in the design, development, creative, and marketing industries. 
          According to their latest research, ABLIMITS STUDIO is listed among the most priming branding companies this 2021!
        </p>
        <p>This honor doesn’t just reflect our versatility, it shows how good we are with every service that we do. We are grateful to have our work recognized and appreciated.</p>
        <div className="quotebox">
          <div className="icon">
            <img src="img/blog/quote.svg" alt="blog quote" />
          </div>
          <p>We design, you download. Go beyond the limits with ABLIMITS STUDIO. <a href="https://www.ablimits.com/">Send us a message</a> and let’s work together!</p>
        </div>
      </div>
      {/* Article Content Ends */}
    </article>
    // Article Ends
  );
};

export default ModalFourBlogContent;

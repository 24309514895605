import styled from "styled-components";
import multiMedia from "./ablimits.mp4";
import soundOn from "./soundon.svg";
import soundOff from "./soundoff.svg";

function index() {
  const toggleMute = () => {
    var video = document.getElementById("myVideo");
    if (video.muted) {
      video.muted = false;
      document.getElementById("onsound").classList.remove("sound-display");
      document.getElementById("offsound").classList.add("sound-display");
    } else {
      video.muted = true;
      document.getElementById("offsound").classList.remove("sound-display");
      document.getElementById("onsound").classList.add("sound-display");
    }
  };
  const Sound = styled.div`
    img {
      max-height:50px;
      font-size: 50px;
      position: absolute;
      bottom: 45%;
      left: 1%;
    }
  `;

  return (
    <section className="main-content video-landscape"> 
      <video id="myVideo" muted autoPlay loop>
        <source src={multiMedia} type="video/mp4" />
      </video>
      <Sound>
        <img id="onsound" onClick={toggleMute} src={soundOn} className="sound-display" alt="SOUND ON"></img>
        <img id="offsound" onClick={toggleMute} src={soundOff} alt="SOUND OFF"></img>
      </Sound>
    </section>
  );
}

export default index;
